<template>
    <div>
        <!-- 房子 -->
        <div class="continer">
            <canvas class="illo"></canvas>
        </div>
        
        <!-- 太阳系 -->
        <!-- <canvas id="canvas"></canvas>
        <div id="note">(Drag to look around)</div> -->
    </div>
</template>

<script>
import animate from '../js/castle.js'
import solarSystem from '../js/solarSystem.js'

export default {
    name: 'showLoading',
    mounted() {
        animate()
        // solarSystem()
    }
}
</script>

<style scoped>
/* 房子 */
.continer{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.illo {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px !important;
    width: 150px !important;
    margin: auto;
    cursor: move;
    z-index: 6;
}

/* 太阳系 */
#canvas {
  width: 100%;
  height: 100%;
  display: block;
  background: #10161a;
  cursor: move;
}
#note {
  position: absolute;
  right: 8px;
  bottom: 4px;
  font: 10pt sans-serif;
  color: #ccc;
}

</style>
