<template>
    <div class="home">
        <!-- <loading class="loading" v-show="loading"></loading> -->
        <div id="cesiumContainer"></div>
        <div class="title_text">中国天宫空间站·实时位置</div>
        <div class="title">
            <el-row :gutter="20">
                <el-col :span="6"><div class="grid-content bg-purple">实时经度：{{longitude}}</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">实时纬度：{{latitude}}</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">实时高度：{{height}} km</div></el-col>
                <el-col :span="6"><div class="grid-content bg-purple">实时速度：{{speed}} m/s</div></el-col>
            </el-row>
        </div>
        <div class="checkSatellite">
            <el-checkbox-group v-model="checkList" @change="CheckedChange">
                <el-checkbox v-for="item in tleList" :key="item.id" :label="item.lable"></el-checkbox>
                <!-- <el-checkbox v-for="item in beidou" :key="item" :label="item"></el-checkbox> -->
            </el-checkbox-group>
        </div>
        <!-- <div class="backMap">
             <el-radio-group v-model="radio" @change="selectMap">
                <el-radio :label="0">高德矢量图</el-radio>
                <el-radio :label="1">高德影像</el-radio>
                <el-radio :label="2">高德路网</el-radio>
                <el-radio :label="3">天地图矢量</el-radio>
                <el-radio :label="4">天地图影像</el-radio>
                <el-radio :label="5">天地图栅格</el-radio>
                <el-radio :label="6">天地图标记</el-radio>
                <el-radio :label="7">Mapbox</el-radio>
                <el-radio :label="8">月球影像</el-radio>
             </el-radio-group>
        </div> -->
        
    </div>
</template>

<script>
import loading from '@/components/Loading.vue'
export default {
    components: {
        // loading
    },
    data() {
        return {
            loading: true,
            Cesium: window.Cesium,
            viewer: void 0,
            tdt: "b63042738fe261d09c04409bdb327567",
            sdtdt: "9cc3e9deb3cf643b6f133717c333d16d",
            gpsData: [],
            longitude: '',
            latitude: '',
            height: '',
            speed: '',
            radio: 4,
            checkList: ['中国空间站'],
            tleList: [
                {
                    "id": '2021-035A',
                    "name": "CSS",
                    "lable": "中国空间站"
                },
                {
                    "id": '1998-067A',
                    "name": "ISS",
                    "lable": "国际空间站"
                },
                {
                    "id": '2023-207B',
                    "name": "BEIDOU-3 M26",
                    "lable": "BEIDOU-3 M26"
                },
                {
                    "id": '2023-066A',
                    "name": "BEIDOU 3 G4",
                    "lable": "BEIDOU 3 G4"
                },
                {
                    "id": '2019-073A',
                    "name": "BEIDOU 3 IGSO-3",
                    "lable": "BEIDOU 3 IGSO-3"
                },
            ],
        }
    },

    mounted() {
        // 初始化Cesium
        this.main()

        // 加载二维地图
        this.addlayer(4)

        // 加载卫星轨道
        setTimeout(() => {
            this.addSatellite()
        }, 500)

        // 加载模型
        // this.addModel()
       
    },

    methods: {
        main() {
            this.viewer = new this.Cesium.Viewer('cesiumContainer', {
                shouldAnimate: true,  // 自动执行动画
                geocoder: false, // 隐藏查找位置
                homeButton: false, // 隐藏返回视角到初始位置
                sceneModePicker: false, // 隐藏视角模式的选择
                baseLayerPicker: false, // 隐藏图层选择器
                navigationHelpButton: false, // 隐藏帮助
                timeline: false, // 隐藏时间轴
                fullscreenButton: false, // 隐藏全屏按钮
                animation: false, // 隐藏动画速度控制器
                infoBox: false, // 点击的详情弹窗
            });

            // 开启光照效果
            this.viewer.scene.globe.enableLighting = true

            // 显示帧率
            this.viewer.scene.debugShowFramesPerSecond = true;

            // 初始化位置、高度、角度、
            this.viewer.camera.setView({
                // 经纬度和高程转换为世界坐标
                destination: this.Cesium.Cartesian3.fromDegrees(112.962475, 28.195802, 20000000),
                orientation: {
                    // 方向
                    heading: 6.283185307179581,
                    // 倾角
                    pitch: -1.5688168484696687
                }
            });

            // 设置当前时间为北京时间
            // this.viewer.clock.currentTime = this.Cesium.JulianDate.addHours(this.Cesium.JulianDate.now(new Date()), 8, new this.Cesium.JulianDate());

        },

        addlayer(type) {
            const Cesium = this.Cesium;
            if (type == 0) {
                //高德矢量图
                let layer = new Cesium.UrlTemplateImageryProvider({
                    url: "https://webrd02.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}",
                    minimumLevel: 3,
                    maximumLevel: 18
                })
                this.viewer.imageryLayers.addImageryProvider(layer);
            } else if (type == 1) {
                //高德影像
                let layer = new Cesium.UrlTemplateImageryProvider({
                    url: "httpss://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
                    minimumLevel: 3,
                    maximumLevel: 18
                })
                this.viewer.imageryLayers.addImageryProvider(layer);
            } else if (type == 2) {
                //高德路网中文注记
                let layer = new Cesium.UrlTemplateImageryProvider({
                    url: "https://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
                    minimumLevel: 3,
                    maximumLevel: 18
                })
                this.viewer.imageryLayers.addImageryProvider(layer);
            } else if (type == 3) {
                //天地图矢量地图
                this.viewer.imageryLayers.addImageryProvider(
                    new Cesium.WebMapTileServiceImageryProvider({
                        url: "https://{s}.tianditu.gov.cn/vec_c/wmts?service=wmts&request=GetTile&version=1.0.0" +
                            "&LAYER=vec&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}" +
                            "&style=default&format=tiles&tk=" + this.tdt,
                        layer: "tdtCva",
                        style: "default",
                        format: "tiles",
                        tileMatrixSetID: "c",
                        subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
                        tilingScheme: new Cesium.GeographicTilingScheme(),
                        tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        maximumLevel: 18
                    })
                );
            } else if (type == 4) {
                //天地图影像地图
                this.viewer.imageryLayers.addImageryProvider(
                    new Cesium.WebMapTileServiceImageryProvider({
                        url: "https://{s}.tianditu.gov.cn/img_c/wmts?service=wmts&request=GetTile&version=1.0.0" +
                            "&LAYER=img&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}" +
                            "&style=default&format=tiles&tk=" + this.tdt,
                        layer: "tdtCva",
                        style: "default",
                        format: "tiles",
                        tileMatrixSetID: "c",
                        subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
                        tilingScheme: new Cesium.GeographicTilingScheme(),
                        tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        maximumLevel: 18
                    })
                );
            } else if (type == 5) {
                //天地图栅格地图
                this.viewer.imageryLayers.addImageryProvider(
                    new Cesium.WebMapTileServiceImageryProvider({
                        url: "https://{s}.tianditu.gov.cn/ter_c/wmts?service=wmts&request=GetTile&version=1.0.0" +
                            "&LAYER=ter&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}" +
                            "&style=default&format=tiles&tk=" + this.tdt,
                        layer: "tdtCva",
                        style: "default",
                        format: "tiles",
                        tileMatrixSetID: "c",
                        subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
                        tilingScheme: new Cesium.GeographicTilingScheme(),
                        tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        maximumLevel: 18
                    })
                );
            } else if (type == 6) {
                //天地图标记地图
                this.viewer.imageryLayers.addImageryProvider(
                    new Cesium.WebMapTileServiceImageryProvider({
                        url: "https://{s}.tianditu.gov.cn/cia_c/wmts?service=wmts&request=GetTile&version=1.0.0" +
                            "&LAYER=cia&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}" +
                            "&style=default&format=tiles&tk=" + this.tdt,
                        layer: "tdtCva",
                        style: "default",
                        format: "tiles",
                        tileMatrixSetID: "c",
                        subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
                        tilingScheme: new Cesium.GeographicTilingScheme(),
                        tileMatrixLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"],
                        maximumLevel: 18
                    })
                );
            } else if (type == 7) {
                //Mapbox
                let mapbox = new Cesium.MapboxStyleImageryProvider({
                    styleId: 'basic-v9',
                    // url: 'https://api.mapbox.com/styles/v1',
                    // username: '17660106630',
                    // styleId: 'clysrzhwi001t01pxdzu4agxa',
                    accessToken: 'pk.eyJ1IjoiMTc2NjAxMDY2MzAiLCJhIjoiY2x5c3RmbDl3MG15azJpcWwxMDBpb2Y1NiJ9.lI3wVw-1dz_Y6d85vn0CBA',
                    // scaleFactor:true,
                    // tilesize: 256
                })
                this.viewer.imageryLayers.addImageryProvider(mapbox)
            } else if (type == 8) {
                let clem = new Cesium.WebMapTileServiceImageryProvider({
                    url: "https://trek.nasa.gov/tiles/Moon/EQ/LRO_LOLA_ClrShade_Global_128ppd_v04/1.0.0//{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}.png",
                    layer: "LRO_LOLA_ClrShade_Global_128ppd_v04",
                    style: "default",
                    format: "image/png",
                    tileMatrixSetID: "default028mm",
                    maximumLevel: 6,
                    tilingScheme: new Cesium.GeographicTilingScheme(),
                    credit: new Cesium.Credit("Clem_UVVIS_FeO_Clr_Global_152ppd"),
                });
                this.viewer.imageryLayers.addImageryProvider(clem)
            } else if (type == 9) {
                //百度影像
                let layer = new Cesium.UrlTemplateImageryProvider({
                    url: "http://online{s}.map.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl&scaler=1&p=1",
                    minimumLevel: 3,
                    maximumLevel: 18
                })
                this.viewer.imageryLayers.addImageryProvider(layer);
            }
        },

        satellite(item) {
            var that = this
            // 读取JOSN文件
            // const json = require("/public/satelliteData/" + item.name + ".json");

            // 读取JOSN文件（服务器版）
            that.$axios.get('https://mapman.cn/cesium/satelliteData/' + item.name + '.json')
                .then( response => {
                    const json = response.data;

                    // 控制速度
                    json[0].clock.multiplier = 1;

                    // 设置实时时间
                    json[0].clock.currentTime = new Date().toISOString();

                    // 修改显示文字
                    json[1].label.text = item.lable;

                    // 修改字体颜色
                    // json[1].label.fillColor = { "rgba": [213,255,0,255] };

                    // 修改轨道颜色
                    // json[1].path.material.solidColor.color = {"rgba": [255,69,0,255]};

                    // 隐藏图片
                    // json[1].billboard.show = false

                    // 加载模型
                    // json[1].model = {
                    //     "show": true,
                    //     "gltf": 'models/airplane.glb',
                    //     "scale": 100,
                    //     "minimumPixelSize": 50,
                    // };

                    // 加载卫星轨道
                    this.viewer.dataSources.add(this.Cesium.CzmlDataSource.load(json)).then(function (dataSource) {
                        item.entitiy = dataSource.entities.getById(json[1].id)

                        // 按checkList隐藏轨道
                        if(that.checkList.indexOf(item.lable)==-1){
                            dataSource.show = false
                        }else{
                            dataSource.show = true
                        }

                        // 添加中国空间站监听
                        var num = 0
                        if (item.lable === that.checkList[0]) {
                            let nowTime = null
                            let cartographic = null
                            that.viewer.clock.onTick.addEventListener(function (clock) {
                                // 1秒执行一次
                                if (nowTime != parseInt(new Date().getTime() / 1000)) {
                                    nowTime = parseInt(new Date().getTime() / 1000)
                                    // console.log(nowTime)

                                    // 获取实时位置
                                    var position = item.entitiy.position.getValue(clock.currentTime);
                                    cartographic = that.Cesium.Cartographic.fromCartesian(position);
                                    that.longitude = that.Cesium.Math.toDegrees(cartographic.longitude).toFixed(2);
                                    that.latitude = that.Cesium.Math.toDegrees(cartographic.latitude).toFixed(2);
                                    that.height = (cartographic.height / 1000).toFixed(2);
                                    // console.log('经度' + that.longitude + '纬度' + that.latitude + '高度' + that.height)

                                    // 计算实时速度
                                    if (that.gpsData.length > 100) {
                                        that.gpsData.splice(0,80)
                                    }
                                    that.gpsData.push({ timestamp: new Date(), latitude: that.latitude, longitude: that.longitude })
                                    that.speed = (that.calculateSpeed(that.gpsData) / 1).toFixed(2)

                                    // 视角跟随
                                    if (num == 0) {
                                        that.viewer.scene.camera.flyTo({
                                            destination: that.Cesium.Cartesian3.fromDegrees(that.longitude, that.latitude, that.height*1000 + 20000000),
                                            orientation: {
                                                // 指向
                                                heading: that.Cesium.Math.toRadians(0,0),
                                                // 视角
                                                pitch: that.Cesium.Math.toRadians(-90),
                                                // 角度
                                                roll: 0.0
                                            },
                                        });
                                        num = 1
                                    }
                                    
                                }
                            })
                        }
                    })
                })

            
        },

        addSatellite() {
            // 添加列表卫星
            for (var i in this.tleList) {
            //    this.satellite(this.tleList[i].name, this.tleList[i].lable)
               this.satellite(this.tleList[i])
           }

            // 添加北斗卫星
            // for (var i in this.beidou) {
            //     this.satellite('BEIDOU/' + this.beidou[i].replace(".json",""), this.beidou[i].replace(".json",""))
            // }

        },

        addModel() {
            // 加载 GLTF 模型  
            var model = this.viewer.entities.add({
                position: new this.Cesium.Cartesian3.fromDegrees(120.14046454, 30.27415039),
                model: {
                    uri: "models/kml.glb", // 指向你的 GLTF 文件的路径
                    scale: 100.0,  // 放大比例
                    minimumPixelSize: 50,  //最小尺寸
                }
            });

            // 聚焦模型
            this.viewer.trackedEntity = model;
        },

        removeSatellite(checkList) {
            // 隐藏轨道
            var that = this
            this.viewer.dataSources._dataSources.forEach(function (ds) {
                var id = ds.entities._id
                for (var i in that.tleList) {
                    var id1 = that.tleList[i].entitiy.entityCollection._id
                    if (id === id1) { // 通过id来识别数据源
                        if(checkList.indexOf(that.tleList[i].lable)==-1){
                            ds.show = false
                        }else{
                            ds.show = true
                        }
                    }
                }
            })

        },

        // 将经纬度转换为弧度  
        toRadians(degrees) {
            return degrees * Math.PI / 180;
        },

        // 计算两点之间的球面距离（Haversine公式）  
        haversineDistance(lat1, lon1, lat2, lon2) {
            // 地球表面的近似平均半径（单位：米）  
            const EARTH_RADIUS = 6371000;
            const dLat = this.toRadians(lat2 - lat1);
            const dLon = this.toRadians(lon2 - lon1);
            lat1 = this.toRadians(lat1);
            lat2 = this.toRadians(lat2);

            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            return EARTH_RADIUS * c;
        },

        // 计算速度（米/秒）  
        calculateSpeed(data) {
            let speeds = [];
            for (let i = 1; i < data.length; i++) {
                const distance = this.haversineDistance(
                    data[i - 1].latitude, data[i - 1].longitude,
                    data[i].latitude, data[i].longitude
                );
                const timeDiff = (data[i].timestamp - data[i - 1].timestamp) / 1000; // 转换为秒  
                const speed = distance / timeDiff;
                speeds.push(speed);
            }

            var sum = 0;
            for (let i = 0; i < speeds.length; i++) {
                sum += speeds[i];
            }
            return sum / speeds.length;
        },

        CheckedChange(value) {
            this.removeSatellite(value)
            console.log(value)

        },

        selectMap(value) {
            this.addlayer(value)
            console.log(value)
        },

    }


}
</script>
<style scoped lang="scss">
    .home {
        width: 100%;
        height: 100%;
        background-color: black;
    }
    #CesiumContainer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        bottom: 0px;
    }

    .loading {
        position: absolute;
        top: 80px;
        left: 50px;
    }

    .title_text {
        position: absolute;
        top: 15px;
        left: 15%;
        width: 70%;
        height: 30%;
        z-index: 10;
        color: white;
        font-weight: bold; 
        font-size: 30px;
        text-align: center;
        line-height: 20px;
    }

    .title {
        position: absolute;
        top: 60px;
        left: 10%;
        width: 80%;
        z-index: 10;
    }

    .checkSatellite {
        position: absolute;
        top: 120px;
        right: 2%;
        width: 110px;
        z-index: 10;
    }

    .backMap {
        position: absolute;
        bottom: 20px;
        right: 1%;
        width: 110px;
        z-index: 10;
    }

    .el-radio{
        padding: 2px;
    }

    
    .el-row {
        margin-bottom: 20px;
        &:last-child {
        margin-bottom: 0;
        }
    }
    .el-col {
        border-radius: 5px;
    }
    .bg-purple {
        background: #242424;
        color: white;
        font-weight: bold; 
        font-size: 18px;
        text-align: center;
        line-height: 45px;
    }
    .grid-content {
        border-radius: 10px;
        min-height: 45px;
    }

</style>

